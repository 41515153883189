import React, { useState } from 'react';
import CryptoJS from 'crypto-js';

const App = () => {
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [encryptedValue, setEncryptedValue] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleEncrypt = () => {
    const encryptedValue = CryptoJS.AES.encrypt(`${company},${token}`, password).toString();
    setEncryptedValue(encryptedValue);
  };

  const handleLogin = () => {
    // Simulating login logic
    if (password === 'sayve54321') {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  };

  return (
    <div>
      {isLoggedIn ? (
        <div>
          <h1>Data Encryption</h1>
          <div>
            <label htmlFor="companyInput">Company:</label>
            <input
              type="text"
              id="companyInput"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="tokenInput">Token:</label>
            <input
              type="text"
              id="tokenInput"
              value={token}
              onChange={(e) => setToken(e.target.value)}
            />
          </div>
          <button onClick={handleEncrypt}>Encrypt</button>
          <div>
            <label htmlFor="encryptedOutput">Encrypted Value:</label>
            <input
              type="text"
              id="encryptedOutput"
              value={encryptedValue}
              readOnly
            />
          </div>
        </div>
      ) : (
        <div>
          <h1>Login</h1>
          <div>
            <label htmlFor="passwordInput">Password:</label>
            <input
              type="password"
              id="passwordInput"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button onClick={handleLogin}>Login</button>
        </div>
      )}
    </div>
  );
};

export default App;
